import React, { useState } from "react";
import { Colors } from "../../colors/Colors";
import { FaqButton, useWindowDimensions } from "../../components";

const Faq = () => {
  const { width, height } = useWindowDimensions();
  const [selected, setSelected] = useState("");
  const [cat, setCat] = useState("log");
  return (
    <div
      style={{
        width: width,
        overflow: "hidden",
        backgroundColor: "#FFFFFF",
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          position: "fixed",
          backgroundColor: "#FFFFFF",
          boxShadow: "1px 1px 0px 0px #00000033",
          top: 0,
        }}
      >
        <img
          src={require("../../assets/GTDLogostandard.png")}
          style={{ height: 60, width: 140, marginLeft: 20 }}
          alt="logo"
        />
        <h1 style={{ marginLeft: 20, color: "#00ABD6", fontSize: 28 }}>
          Frequently asked questions
        </h1>
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          // backgroundColor: Colors.primary,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div style={{ height: 70, width: "100%" }} />
        <div style={{ height: width < 899 ? 50 : 20, width: "100%" }} />
        <div>Click on question for more info</div>

        <div
          style={{
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "scroll",
            width: "100%",
          }}
        >
          <div
            style={{
              height: cat === "log" ? null : 80,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaqButton
              title={"Sign up and Login"}
              onClick={() => {
                setCat("log");
                setSelected("");
              }}
              selected={cat === "log" ? true : false}
              onClickClose={() => {
                setCat("");
                setSelected("");
              }}
              catagory={true}
              answer={
                <>
                  <FaqButton
                    sectionHeight={100}
                    title={"How do I Get login details?"}
                    onClick={() => setSelected(1)}
                    selected={selected === 1 ? true : false}
                    answer={
                      "You need to contact us and join as a client, each client is vetted through a credit check for accounts. If COD all you need to do is give us a call"
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                  />
                  <FaqButton
                    title={"How do I Sign Up?"}
                    onClick={() => setSelected(2)}
                    selected={selected === 2 ? true : false}
                    answer={
                      "Sign up is only necessary if you will be come a long standing client or join our subscription services. If you still need to sign up contact our lovely customer care and they will sign you up."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"Do I need to do credit Application?"}
                    onClick={() => setSelected(3)}
                    selected={selected === 3 ? true : false}
                    answer={
                      "Only if you will become a client with a account, you will not need it for our subscription services"
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"What do I need for the credit Application?"}
                    onClick={() => setSelected(4)}
                    selected={selected === 4 ? true : false}
                    answer={
                      "Will will need all the standard documents when applying for credit if your not sure please contact our customer care or service agents and they will assist in getting it all together."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"Whats is the Customer login for?"}
                    onClick={() => setSelected(5)}
                    selected={selected === 5 ? true : false}
                    answer={
                      "its to allow our customers access to our frieght system which can allow you to track your parcels and and create waybels for our system for deliveries"
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"Do i have to sign up to be a client?"}
                    onClick={() => setSelected(6)}
                    selected={selected === 6 ? true : false}
                    answer={
                      "No that is not always the case but you can contact our customer line and they will see if it is required for you to join."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                </>
              }
            />
            <FaqButton
              title={"How do i track my parcel"}
              onClick={() => {
                setCat("track");
                setSelected("");
              }}
              selected={cat === "track" ? true : false}
              onClickClose={() => {
                setCat("");
                setSelected("");
              }}
              catagory={true}
              answer={
                <>
                  <FaqButton
                    sectionHeight={100}
                    title={"Where do I go to track my parcel?"}
                    onClick={() => setSelected(1)}
                    selected={selected === 1 ? true : false}
                    answer={
                      "on the home screen of our website is a tracking button that will link you to our system. Just remember it is made for a larger screen setup and not always mobile compatible."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                  />
                  <FaqButton
                    title={"Do you collect the parcel?"}
                    onClick={() => setSelected(2)}
                    selected={selected === 2 ? true : false}
                    answer={
                      "Yes we collect the parcel within our Dc hub radius, other areas can be done on request."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"How long does the Economy service take?"}
                    onClick={() => setSelected(3)}
                    selected={selected === 3 ? true : false}
                    answer={
                      "Anywhere from 3 to 7 days depending on the route, this is a standard no rush process"
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"Do you store and warehouse products for clients?"}
                    onClick={() => setSelected(4)}
                    selected={selected === 4 ? true : false}
                    answer={
                      "Please contact our area Rep/Manager on this to if we can assist you in the matter in anyway."
                    }
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                </>
              }
            />
            {/* <FaqButton
              title={"What are you services"}
              onClick={() => {
                setCat("ser");
                setSelected("");
              }}
              selected={cat === "ser" ? true : false}
              onClickClose={() => {
                setCat("");
                setSelected("");
              }}
              catagory={true}
              answer={
                <>
                  <FaqButton
                    sectionHeight={100}
                    title={"How do I Get login details"}
                    onClick={() => setSelected(1)}
                    selected={selected === 1 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                  />
                  <FaqButton
                    title={"How do I Sign Up"}
                    onClick={() => setSelected(2)}
                    selected={selected === 2 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"How do I Get login details"}
                    onClick={() => setSelected(3)}
                    selected={selected === 3 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"How do I Sign Up"}
                    onClick={() => setSelected(4)}
                    selected={selected === 4 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"How do I Get login details"}
                    onClick={() => setSelected(5)}
                    selected={selected === 5 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                  <FaqButton
                    title={"How do I Sign Up"}
                    onClick={() => setSelected(6)}
                    selected={selected === 6 ? true : false}
                    answer={"answer the info"}
                    setSelected={setSelected}
                    onClickClose={() => setSelected("")}
                    sectionHeight={100}
                  />
                </>
              }
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
