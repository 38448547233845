import React, {useState} from "react";
import { Colors } from "../../../colors/Colors";

const StandardButton = ({title,onClick}) => {
    const [hover, setHover] = useState(false)
    return(
        <div 
        onMouseOver={()=> setHover(true)}
        onMouseOut={()=> setHover(false)}
        onClick={onClick}

        style={{
            height: 60,
            // width: 120,
            backgroundColor:hover ?Colors.secondary :  Colors.primary,
            color:Colors.white, borderRadius: 8,
            justifyContent:'center', 
            alignItems:'center', 
            fontWeight:'bold', 
            display:'flex',
            fontSize: 22,
            cursor:"pointer",
            marginTop:10,
            marginLeft:10,
            padding:20,
        }}>
            {title}
        </div>
    )
}

export default StandardButton;