import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components";

import { MainPage, Rates, Faq, Tutorials,  } from "./screens";

const App = () => {
  // state = {
  //   data: null,

  // };

  // componentDidMount() {
  //   this.callBackendAPI()
  //     .then(res => this.setState({ data: res }))
  //     .catch(err => console.log(err));
  // }
  // // fetching the GET route from the Express server which matches the GET route from server.js
  // callBackendAPI = async () => {
  //   const response = await fetch('/express_backend');
  //   const body = await response.json();

  //   if (response.status !== 200) {

  //     throw Error(body.message)
  //   }
  //   return body;
  // };

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="Rates" element={<Rates />} />
        <Route path="Faq" element={<Faq />} />
        <Route path="Tuts" element={<Tutorials/>}/>
        {/* <Route path="contact" element={<Contact />} /> */}
        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
