import React from "react";
import Rows from "./rows/Rows";

const RatesTable = ({
  ratesDisplayAdjuster,
  title,
  headerOne,
  headerTwo,
  headerThree,
  headerFour,
  headerFive,
  h1Block2,
  h1Block3,
  h1Block4,
  h1Block5,
  h1Block6,
  h2Block2,
  h2Block3,
  h2Block4,
  h2Block5,
  h2Block6,
  h3Block2,
  h3Block3,
  h3Block4,
  h3Block5,
  h3Block6,
  h4Block2,
  h4Block3,
  h4Block4,
  h4Block5,
  h4Block6,
  h5Block2,
  h5Block3,
  h5Block4,
  h5Block5,
  h5Block6,
  h1Change2,
  h1Change3,
  h1Change4,
  h1Change5,
  h1Change6,
  h3Change2,
  h3Change3,
  h3Change4,
  h3Change5,
  h3Change6,
  h4Change2,
  h4Change3,
  h4Change4,
  h4Change5,
  h4Change6,
  h5Change2,
  h5Change3,
  h5Change4,
  h5Change5,
  h5Change6,
  h6Change2,
  h6Change3,
  h6Change4,
  h6Change5,
  h6Change6,
  onClickB2h2,
  onClickB2h3,
  onClickB2h4,
  onClickB2h5,
  onClickB2h6,
  onClickB3h2,
  onClickB3h3,
  onClickB3h4,
  onClickB3h5,
  onClickB3h6,
  onClickB4h2,
  onClickB4h3,
  onClickB4h4,
  onClickB4h5,
  onClickB4h6,
  onClickB5h2,
  onClickB5h3,
  onClickB5h4,
  onClickB5h5,
  onClickB5h6,
  onClickB6h2,
  onClickB6h3,
  onClickB6h4,
  onClickB6h5,
  onClickB6h6,
  type,
}) => {
  return (
    <div
      style={{
        height: type ? 180 : 250,
        width: type ? 400 : 600,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3
        style={{
          marginTop: 10,
          marginBottom: 20,
          fontWeight: "bold",
          color: "#00ABD6",
          fontSize: type ? 12 : 24,
        }}
      >
        {title}
      </h3>
      <div
        style={{
          flex: 0.2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 0.2,
            borderStyle: "solid",
            borderWidth: 0.2,
            borderColor: "#FFFFFF",
          }}
        ></div>
        <div
          style={{
            flex: 0.16,
            borderStyle: "solid",
            borderWidth: 0.2,
            backgroundColor: "#00ABD6",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            borderTopLeftRadius: 10,
            fontSize: type ? 12 : 24,
          }}
        >
          {headerOne}
        </div>
        <div
          style={{
            flex: 0.16,
            borderStyle: "solid",
            borderWidth: 0.2,
            backgroundColor: "#00ABD6",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: type ? 12 : 24,
          }}
        >
          {headerTwo}
        </div>
        <div
          style={{
            flex: 0.16,
            borderStyle: "solid",
            borderWidth: 0.2,
            backgroundColor: "#00ABD6",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: type ? 12 : 24,
          }}
        >
          {headerThree}
        </div>
        <div
          style={{
            flex: 0.16,
            borderStyle: "solid",
            borderWidth: 0.2,
            backgroundColor: "#00ABD6",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: type ? 12 : 24,
          }}
        >
          {headerFour}
        </div>
        <div
          style={{
            flex: 0.16,
            borderStyle: "solid",
            borderWidth: 0.2,
            backgroundColor: "#00ABD6",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            borderTopRightRadius: 10,
            fontSize: type ? 12 : 24,
          }}
        >
          {headerFive}
        </div>
      </div>
      <Rows
        block1={"JHB"}
        block2={h1Block2}
        onClickB2={onClickB2h2}
        change2={h1Change2}
        block3={h1Block3}
        onClickB3={onClickB2h3}
        change3={h1Change3}
        block4={h1Block4}
        onClickB4={onClickB2h4}
        change4={h1Change4}
        block5={h1Block5}
        onClickB5={onClickB2h5}
        change5={h1Change5}
        block6={h1Block6}
        onClickB6={onClickB2h6}
        change6={h1Change6}
      />
      <Rows
        block1={"CPT"}
        block2={h2Block2}
        onClickB2={onClickB3h2}
        onClickB3={onClickB3h3}
        onClickB4={onClickB3h4}
        onClickB5={onClickB3h5}
        onClickB6={onClickB3h6}
        change2={h3Change2}
        change3={h3Change3}
        change4={h3Change4}
        change5={h3Change5}
        change6={h3Change6}
        block3={h2Block3}
        block4={h2Block4}
        block5={h2Block5}
        block6={h2Block6}
      />
      <Rows
        block1={"DUR"}
        onClickB2={onClickB4h2}
        onClickB3={onClickB4h3}
        onClickB4={onClickB4h4}
        onClickB5={onClickB4h5}
        onClickB6={onClickB4h6}
        change2={h4Change2}
        change3={h4Change3}
        change4={h4Change4}
        change5={h4Change5}
        change6={h4Change6}
        block2={h3Block2}
        block3={h3Block3}
        block4={h3Block4}
        block5={h3Block5}
        block6={h3Block6}
      />
      <Rows
        block1={"PTA"}
        onClickB2={onClickB5h2}
        onClickB3={onClickB5h3}
        onClickB4={onClickB5h4}
        onClickB5={onClickB5h5}
        onClickB6={onClickB5h6}
        change2={h5Change2}
        change3={h5Change3}
        change4={h5Change4}
        change5={h5Change5}
        change6={h5Change6}
        block2={h4Block2}
        block3={h4Block3}
        block4={h4Block4}
        block5={h4Block5}
        block6={h4Block6}
      />
      <Rows
        block1={"PMB"}
        onClickB2={onClickB6h2}
        onClickB3={onClickB6h3}
        onClickB4={onClickB6h4}
        onClickB5={onClickB6h5}
        onClickB6={onClickB6h6}
        change2={h6Change2}
        change3={h6Change3}
        change4={h6Change4}
        change5={h6Change5}
        change6={h6Change6}
        block2={h5Block2}
        block3={h5Block3}
        block4={h5Block4}
        block5={h5Block5}
        block6={h5Block6}
      />
    </div>
  );
};
export default RatesTable;
