import React, { useState } from "react";

const TabButton = ({ title, onClick, change }) => {
  const [hover, setHover] = useState(false);
  return (
    <h4
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        backgroundColor: hover
          ? "#00ABD6"
          : change === title
          ? "#131f48"
          : "#131f4866",
        color: "#FFFFFF",
        padding: 10,
        borderRadius: 10,
        transition: "all 0.1s",
        cursor: "pointer",
      }}
    >
      {title}
    </h4>
  );
};

export default TabButton;
