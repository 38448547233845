import React, { useState } from "react";
import { Colors } from "../../colors/Colors";

const FaqButton = ({
  title,
  answer,
  selected,
  onClick,
  onClickClose,
  catagory,
  sectionHeight,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <div
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          width: "90%",
          height: "95%",
          borderStyle: "solid",
          borderRadius: 10,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          borderColor: Colors.primary,
          cursor: "pointer",
          overflow: "hidden",
          marginBottom: 20,
        }}
      >
        <div
          onClick={onClick}
          style={{
            height: 80,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: hover ? "#00ABD666" : Colors.white,
            width: "100%",
            borderRadius: 8,
            textAlign: "left",
          }}
        >
          <div
            style={{
              marginLeft: 20,
              fontWeight: "bold",
              fontSize: catagory ? 28 : 20,
              color: Colors.secondary,
            }}
          >
            {title}
          </div>
        </div>
        <div
          style={{
            height: selected ? (catagory ? null : sectionHeight) : 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            transition: "all 0.8s",
            // borderStyle: "solid",
            width: "90%",
          }}
        >
          {answer}
        </div>
        <div
          onClick={onClickClose}
          style={{
            cursor: "pointer",
            height: selected ? null : 0,
            overflow: "hidden",
            position: "absolute",
            right: 30,
            top: 30,
          }}
        >
          CLOSE
        </div>
      </div>
    </div>
  );
};
export default FaqButton;
