import React from "react";

const TablePalletRow = ({
  colorBack,
  line1,
  line2,
  amount,
  onChange,
  check,
}) => {
  return (
    <div
      style={{
        width: "90%",

        height: 40,
        backgroundColor: colorBack,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        flexDirection: "row",
      }}
    >
      <div
        style={{
          flex: 0.4,
          borderStyle: "solid",
          height: "100%",
          borderWidth: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {line1}
      </div>
      <div
        style={{
          flex: 0.3,
          borderStyle: "solid",
          borderWidth: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {line2}
      </div>
      <div
        style={{
          flex: 0.3,
          borderStyle: "solid",
          borderWidth: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {check ? (
          <form
            // className="contact-form"
            //   onSubmit={sendEmail}
            style={{
              display: "flex",
              flexDirection: "row",
              // width: "200px",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            R
            <input
              value={amount}
              style={{
                marginLeft: 10,
                width: "100%",
                height: "80%",
                borderWidth: "0.5px",
                //   borderRadius: 8,
                color: "#131f48",
                //   borderStyle: "solid",
              }}
              onChange={onChange}
              type="text"
            />
          </form>
        ) : null}
      </div>
    </div>
  );
};
export default TablePalletRow;
