import React, { useState } from "react";

const ClientPortalButton = () => {
  const [hover, setHover] = useState(false);
  return (
    <a href="http://gtdexpress.podzone.net/WintrackDX_GTD/Account/Login">
      <div
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          cursor: "pointer",
          height: hover ? 80 : 60,
          width: hover ? 250 : 200,
          backgroundColor: "#131f48",
          display: "flex",
          color: "#FFFFFF",
          fontWeight: "bold",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          borderStyle: "solid",
          borderColor: "#00ABD6",
          right: 20,
          bottom: 20,
          transition: "all 0.5s",
        }}
      >
        Track your shipment
      </div>
    </a>
  );
};

export default ClientPortalButton;
