import React from "react";

const WhyChooseComponent = ({ width, quoteBanner }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: width > 599 ? "row" : "column",
        // width: "100%",
        margin: "30px",
        overflow: "hidden",
        //   maxHeight: "350px",
        paddingTop: quoteBanner ? null : 120,
      }}
    >
      <div
        style={{
          flex: 0.8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: "100%",
          //   marginLeft: "40px",
          overflow: "hidden",
          marginTop: "40px",
        }}
      >
        <h
          style={{
            fontSize: "40px",
            fontWeight: "bold",
            color: "#00ABD6",
            marginBottom: "10px",
          }}
        >
          Why should you choose GTD ?
        </h>
        <p style={{ fontSize: "24px" }}>
          We take the time to understand the unique needs of your business and
          distribution requirements, to formulate a distribution solution
          tailored assisting you in creating and maintaining a competitive edge.
          With three branches located in Cape Town, Johannesburg and Durban, we
          have the perfect break bulk or full load roadfreight solution for your
          business, so speak to us and get your load on the road with GTD.
        </p>
      </div>
      <img
        src={require("../../assets/Pallet.png")}
        className="pallet-image"
        style={{ flex: 0.2, height: width > 599 ? "320px" : "150px" }}
        alt="Loading banner"
      />
    </div>
  );
};

export default WhyChooseComponent;
