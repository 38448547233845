import React from "react";
import emailjs from "emailjs-com";

export default function QouteClickCompnent({ setFormsDrop, width }) {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID_QUOTE,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormsDrop(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <form
      className="contact-form"
      onSubmit={sendEmail}
      style={{
        display: "flex",
        flexDirection: width > 599 ? "row" : "column",
        // width: "300px",
        flex: 1,
        position: "relative",
      }}
    >
      <div
        style={{
          flex: 0.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItem: "center",
        }}
      >
        <div
          style={{
            color: "#00ABD6",
            fontWeight: "bold",
            fontSize: "24px",
            marginLeft: "40px",
            marginBottom: "20px",
          }}
        >
          Personal Information
        </div>
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Company name
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="company_name"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Contact person
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="user_name"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Address
        </label>
        <textarea
          style={{
            marginBottom: 10,
            width: "300px",
            height: "60px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          name="address"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Email
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="email"
          name="user_email"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Cell Number
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="cell_number"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Landline
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="landline"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Information you require sales representative to present to you
        </label>
        <textarea
          style={{
            marginBottom: 10,
            width: "300px",
            height: "60px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          name="message"
        />
      </div>
      <div style={{ flex: 0.5, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            color: "#00ABD6",
            fontWeight: "bold",
            fontSize: "24px",
            marginLeft: "40px",
            marginBottom: "20px",
          }}
        >
          Destination
        </div>

        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Destination Address
        </label>
        <textarea
          style={{
            marginBottom: 10,
            width: "300px",
            height: "60px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          name="destination_address"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Province
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="province"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Service required as on our website
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="service"
        />
        <div
          style={{
            color: "#00ABD6",
            fontWeight: "bold",
            fontSize: "24px",
            marginLeft: "40px",
            marginBottom: "20px",
          }}
        >
          Dimensions
        </div>
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Length (in cm)
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="length"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Width (in cm)
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="width"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Height (in cm)
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="height"
        />
        <label
          style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}
        >
          Weight (in Kg)
        </label>
        <input
          style={{
            marginBottom: 10,
            width: "300px",
            height: "30px",
            borderWidth: "2px",
            borderRadius: 8,
            color: "#131f48",
            //   borderStyle: "solid",
          }}
          type="text"
          name="weight"
        />
      </div>

      <input
        style={{
          height: "60px",
          borderRadius: "10px",
          backgroundColor: "#131f48",
          color: "#FFFFFF",
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "20px",
          position: "absolute",
          width: "400px",
          bottom: -60,
          right: width > 599 ? "30%" : null,
          left: width > 599 ? null : 0,
        }}
        type="submit"
        value="Send"
      />
    </form>
  );
}
