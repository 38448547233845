import React, { useState } from "react";
import EmailComponent from "./email-components/EmailCompnent";
import QouteClickCompnent from "./email-components/QouteClickCompnent";

const QouteComponent = ({ quoteBanner, setQuoteBanner, width }) => {
  const [hover, setHover] = useState(null);
  const [formsDrop, setFormsDrop] = useState(false);
  const [choice, setChoice] = useState(false);

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: quoteBanner ? (formsDrop ? 0 : "200px") : 0,
          transition: "height 0.5s",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          onClick={() => {
            setQuoteBanner(false);
            setFormsDrop(false);
          }}
          style={{
            fontWeight: "bold",
            color: "#00ABD6",
            position: "absolute",
            right: 20,
            top: 20,
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          CLOSE
        </div>
        <div
          onMouseOver={() => setHover("rep")}
          onMouseOut={() => setHover(null)}
          onClick={() => {
            setFormsDrop(true);
            setChoice(true);
          }}
          style={{
            height: hover === "rep" ? 80 : 65,
            width: hover === "rep" ? 220 : 200,
            backgroundColor: hover === "rep" ? "#00ABD6" : "#131f48",
            borderRadius: 10,
            boxShadow: "1px 2px 9px #00000066",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            color: hover === "rep" ? "#131f48" : "#FFFFFF",
            transition: "all 0.5s",
            cursor: "pointer",
          }}
        >
          REQUIRE A SALES REP
        </div>
        <div
          onMouseOver={() => setHover("quote")}
          onMouseOut={() => setHover(null)}
          onClick={() => {
            setFormsDrop(true);
            setChoice(false);
          }}
          style={{
            height: hover === "quote" ? 80 : 65,
            width: hover === "quote" ? 220 : 200,
            backgroundColor: hover === "quote" ? "#00ABD6" : "#131f48",
            borderRadius: 10,
            boxShadow: "1px 2px 9px #00000066",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            color: hover === "quote" ? "#131f48" : "#FFFFFF",
            transition: "all 0.5s",
            cursor: "pointer",
          }}
        >
          Get a Quote
        </div>
      </div>
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          height: formsDrop
            ? choice
              ? "750px"
              : width > 599
              ? 800
              : "1450px"
            : 0,
          transition: "height 0.5s",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "relative",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          onClick={() => {
            setFormsDrop(false);
          }}
          style={{
            fontWeight: "bold",
            color: "#00ABD6",
            position: "absolute",
            right: 20,
            top: 20,
            fontSize: "24px",
            cursor: "pointer",
          }}
        >
          BACK
        </div>
        <div style={{ display: "flex" }}>
          {choice ? (
            <EmailComponent setFormsDrop={setFormsDrop} />
          ) : (
            <QouteClickCompnent width={width} setFormsDrop={setFormsDrop} />
          )}
        </div>
      </div>
    </>
  );
};

export default QouteComponent;
