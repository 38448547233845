import React, { useState } from "react";

const SocialButton = ({ link, pic }) => {
  const [hover, setHover] = useState(false);
  return (
    <a href={link}>
      <img
        src={pic}
        alt="loading"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          height: hover ? 80 : 60,
          width: hover ? 80 : 60,
          transition: "all 0.3s",
        }}
      />
    </a>
  );
};

export default SocialButton;
