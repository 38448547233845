import React, { useState } from "react";
import {
  RatesAdjuster,
  RatesTable,
  TabButton,
  useWindowDimensions,
} from "../../components";
import JsPDF from "jspdf";
import { Colors } from "../../colors/Colors";
import { TablePalletRow } from "./components";
import PalletTable from "./components/pallet-table/PalletTable";

const Rates = () => {
  const { width, height } = useWindowDimensions();
  const [rateView, setRateView] = useState("PREFERENTIAL RATES");
  const [percentValue, setPercentValue] = useState(67.5);
  const [ratesDisplayAdjuster, setRatesDisplayAdjsuter] = useState("");
  const [rateAmount, setRateAmount] = useState(null);
  const [surcharge, setSurcharge] = useState(40);
  const [palletSurcharge, setPalletSurcharge] = useState(40);

  const [customerName, setCustomerName] = useState("");
  const [agentName, setAgentName] = useState("");

  const [jhbToDurRate1to4, setJhbToDurRate1to4] = useState(850.0);
  const [jhbToDurRate5to8, setJhbToDurRate5to8] = useState(750.0);
  const [jhbToCptRate1to4, setJhbToCptRate1to4] = useState(1650.0);
  const [jhbToCptRate5to8, setJhbToCptRate5to8] = useState(1450.0);
  const [dbnToCptRate1to4, setDbnToCptRate1to4] = useState(2850.0);
  const [dbnToCptRate5to8, setDbnToCptRate5to8] = useState(2400.0);

  // Jhb line
  const [jhbToJhb, setJhbToJhb] = useState(0.9);
  const [jhbToCpt, setJhbToCpt] = useState(2.28);
  const [jhbToDur, setJhbToDur] = useState(1.68);
  const [jhbToPta, setJhbToPta] = useState(1.2);
  const [jhbToPmb, setJhbToPmb] = useState(1.92);
  //CPT line
  const [cptTocpt, setCpttoCpt] = useState(0.9);
  const [cptToDur, setCptToDur] = useState(2.64);
  const [cptToPta, setCptToPta] = useState(2.52);
  const [cptToPmb, setCptToPmb] = useState(3.12);
  //DUR line
  const [durToDur, setDurToDur] = useState(0.9);
  const [durToPta, setDurtoPta] = useState(2.04);
  const [durToPmb, setDurtoPmb] = useState(1.2);
  //PTA line
  const [ptaToPta, setPtaToPta] = useState(0.9);
  const [ptaToPmb, setPtaToPmb] = useState(2.52);
  //PMB line
  const [pmbToPmb, setPmbtoPmb] = useState(0.9);
  const [pdfDisplay, setPdfDisplay] = useState(false);
  const generatePDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.save("report.pdf");
    });
  };
  const generatePalletPDF = () => {
    const report = new JsPDF("portrait", "pt", "a4");
    report.html(document.querySelector("#palletreport")).then(() => {
      report.save("report.pdf");
    });
  };
  const RateChangerHandler = async () => {
    //JHB line
    if (ratesDisplayAdjuster === "JHB to JHB") {
      setJhbToJhb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "JHB to CPT") {
      setJhbToCpt(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "JHB to DUR") {
      setJhbToDur(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "JHB to PTA") {
      setJhbToPta(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "JHB to PMB") {
      setJhbToPmb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "CPT to CPT") {
      setCpttoCpt(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "CPT to DUR") {
      setCptToDur(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "CPT to PTA") {
      setCptToPta(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "CPT to PMB") {
      setCptToPmb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "DUR to DUR") {
      setDurToDur(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "DUR to PTA") {
      setDurtoPta(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "DUR to PMB") {
      setDurtoPmb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "PTA to PTA") {
      setPtaToPta(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "PTA to PMB") {
      setPtaToPmb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    } else if (ratesDisplayAdjuster === "PMB to PMB") {
      setPmbtoPmb(Number(rateAmount));
      setRatesDisplayAdjsuter("");
      setRateAmount(null);
    }
  };

  const ExpressRate = () => {
    //JHB line
    const h1Block2Rate = (jhbToJhb / 100) * percentValue + jhbToJhb;
    const h1Block3Rate = (jhbToCpt / 100) * percentValue + jhbToCpt;
    const h1Block4Rate = (jhbToDur / 100) * percentValue + jhbToDur;
    const h1Block5Rate = (jhbToPta / 100) * percentValue + jhbToPta;
    const h1Block6Rate = (jhbToPmb / 100) * percentValue + jhbToPmb;
    // CPT line
    const h2Block2Rate = (jhbToCpt / 100) * percentValue + jhbToCpt;
    const h2Block3Rate = (cptTocpt / 100) * percentValue + cptTocpt;
    const h2Block4Rate = (cptToDur / 100) * percentValue + cptToDur;
    const h2Block5Rate = (cptToPta / 100) * percentValue + cptToPta;
    const h2Block6Rate = (cptToPmb / 100) * percentValue + cptToPmb;
    //dur line
    const h3Block2Rate = (jhbToDur / 100) * percentValue + jhbToDur;
    const h3Block3Rate = (cptToDur / 100) * percentValue + cptToDur;
    const h3Block4Rate = (durToDur / 100) * percentValue + durToDur;
    const h3Block5Rate = (durToPta / 100) * percentValue + durToPta;
    const h3Block6Rate = (durToPmb / 100) * percentValue + durToPmb;
    //Pta line
    const h4Block2Rate = (jhbToPta / 100) * percentValue + jhbToPta;
    const h4Block3Rate = (cptToPta / 100) * percentValue + cptToPta;
    const h4Block4Rate = (durToPta / 100) * percentValue + durToPta;
    const h4Block5Rate = (ptaToPta / 100) * percentValue + ptaToPta;
    const h4Block6Rate = (ptaToPmb / 100) * percentValue + ptaToPmb;
    //Pmb line
    const h5Block2Rate = (jhbToPmb / 100) * percentValue + jhbToPmb;
    const h5Block3Rate = (cptToPmb / 100) * percentValue + cptToPmb;
    const h5Block4Rate = (durToPmb / 100) * percentValue + durToPmb;
    const h5Block5Rate = (ptaToPmb / 100) * percentValue + ptaToPmb;
    const h5Block6Rate = (pmbToPmb / 100) * percentValue + pmbToPmb;
    return {
      JHB: {
        h1B2Rate: h1Block2Rate,
        h1B3Rate: h1Block3Rate,
        h1B4Rate: h1Block4Rate,
        h1B5Rate: h1Block5Rate,
        h1B6Rate: h1Block6Rate,
      },
      CPT: {
        h1B2Rate: h2Block2Rate,
        h1B3Rate: h2Block3Rate,
        h1B4Rate: h2Block4Rate,
        h1B5Rate: h2Block5Rate,
        h1B6Rate: h2Block6Rate,
      },
      DUR: {
        h1B2Rate: h3Block2Rate,
        h1B3Rate: h3Block3Rate,
        h1B4Rate: h3Block4Rate,
        h1B5Rate: h3Block5Rate,
        h1B6Rate: h3Block6Rate,
      },
      PTA: {
        h1B2Rate: h4Block2Rate,
        h1B3Rate: h4Block3Rate,
        h1B4Rate: h4Block4Rate,
        h1B5Rate: h4Block5Rate,
        h1B6Rate: h4Block6Rate,
      },
      PMB: {
        h1B2Rate: h5Block2Rate,
        h1B3Rate: h5Block3Rate,
        h1B4Rate: h5Block4Rate,
        h1B5Rate: h5Block5Rate,
        h1B6Rate: h5Block6Rate,
      },
    };
  };

  return (
    <div
      style={{
        width: width,
        overflow: "hidden",
        backgroundColor: "#00ABD633",
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          position: "fixed",
          backgroundColor: "#FFFFFF",
          boxShadow: "1px 1px 0px 0px #00000033",
          top: 0,
        }}
      >
        <img
          src={require("../../assets/GTDLogostandard.png")}
          style={{ height: 60, width: 140, marginLeft: 20 }}
          alt="logo"
        />
        <h1 style={{ marginLeft: 20, color: "#00ABD6", fontSize: 28 }}>
          Rates Dashboard
        </h1>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <form
            // className="contact-form"
            //   onSubmit={sendEmail}
            style={{
              display: "flex",
              flexDirection: "row",
              // width: "200px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label
              style={{
                fontWeight: "bold",
                color: "#131f48",
                fontSize: 10,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              Customer Name
            </label>

            <input
              // value={surcharge}
              style={{
                width: "100%",
                height: "30px",
                borderWidth: "2px",
                borderRadius: 8,
                color: "#131f48",
                //   borderStyle: "solid",
              }}
              onChange={(e) => setCustomerName(e.target.value)}
              type="text"
            />
            <label
              style={{
                marginLeft: 10,

                fontWeight: "bold",
                color: "#131f48",
                fontSize: 10,
                marginRight: 10,
              }}
            >
              Agent Name
            </label>

            <input
              // value={surcharge}
              style={{
                width: "100%",
                height: "30px",
                borderWidth: "2px",
                borderRadius: 8,
                color: "#131f48",
                //   borderStyle: "solid",
              }}
              onChange={(e) => setAgentName(e.target.value)}
              type="text"
            />
          </form>
          <TabButton
            title={"PREFERENTIAL RATES"}
            change={rateView}
            onClick={() => setRateView("PREFERENTIAL RATES")}
          />
          <TabButton
            title={"PALLET RATES"}
            change={rateView}
            onClick={() => setRateView("PALLET RATES")}
          />
        </div>
        <div style={{ marginRight: 20 }}>menu</div>
      </div>
      {/* // pref rates */}
      {rateView === "PREFERENTIAL RATES" ? (
        <div
          style={{
            paddingTop: 80,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              height: "95%",
              width: "95%",
              boxShadow: "1px 1px 1px 1px #00000033",
              display: "flex",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RatesTable
                title={"OVERNIGHT ROAD-FREIGHT"}
                headerOne={"JHB"}
                h1Block2={"R" + (2.0).toFixed(2)}
                h1Block3={"R" + (13.63).toFixed(2)}
                h1Block4={"R" + (4.5).toFixed(2)}
                h1Block5={"R" + (5.1).toFixed(2)}
                h1Block6={"R" + (5.55).toFixed(2)}
                headerTwo={"CPT"}
                h2Block2={"R" + (13.63).toFixed(2)}
                h2Block3={"R" + (2.0).toFixed(2)}
                h2Block4={"on request"}
                h2Block5={"R" + (14.1).toFixed(2)}
                h2Block6={"on request"}
                headerThree={"DUR"}
                h3Block2={"R" + (4.5).toFixed(2)}
                h3Block3={"on request"}
                h3Block4={"R" + (2.0).toFixed(2)}
                h3Block5={"R" + (5.55).toFixed(2)}
                h3Block6={"R" + (2.5).toFixed(2)}
                headerFour={"PTA"}
                h4Block2={"R" + (5.1).toFixed(2)}
                h4Block3={"R" + (14.1).toFixed(2)}
                h4Block4={"R" + (5.55).toFixed(2)}
                h4Block5={"R" + (2.0).toFixed(2)}
                h4Block6={"R" + (6.5).toFixed(2)}
                headerFive={"PMB"}
                h5Block2={"R" + (5.55).toFixed(2)}
                h5Block3={"on request"}
                h5Block4={"R" + (2.5).toFixed(2)}
                h5Block5={"R" + (6.5).toFixed(2)}
                h5Block6={"R" + (2.0).toFixed(2)}
              />
              <div style={{ height: 20 }} />
              <RatesTable
                title={"EXPRESS ROAD-FREIGHT"}
                headerOne={"JHB"}
                h1Block2={"R" + ExpressRate().JHB.h1B2Rate.toFixed(2)}
                h1Block3={"R" + ExpressRate().JHB.h1B3Rate.toFixed(2)}
                h1Block4={"R" + ExpressRate().JHB.h1B4Rate.toFixed(2)}
                h1Block5={"R" + ExpressRate().JHB.h1B5Rate.toFixed(2)}
                h1Block6={"R" + ExpressRate().JHB.h1B6Rate.toFixed(2)}
                headerTwo={"CPT"}
                h2Block2={"R" + ExpressRate().CPT.h1B2Rate.toFixed(2)}
                h2Block3={"R" + ExpressRate().CPT.h1B3Rate.toFixed(2)}
                h2Block4={"R" + ExpressRate().CPT.h1B4Rate.toFixed(2)}
                h2Block5={"R" + ExpressRate().CPT.h1B5Rate.toFixed(2)}
                h2Block6={"R" + ExpressRate().CPT.h1B6Rate.toFixed(2)}
                headerThree={"DUR"}
                h3Block2={"R" + ExpressRate().DUR.h1B2Rate.toFixed(2)}
                h3Block3={"R" + ExpressRate().DUR.h1B3Rate.toFixed(2)}
                h3Block4={"R" + ExpressRate().DUR.h1B4Rate.toFixed(2)}
                h3Block5={"R" + ExpressRate().DUR.h1B5Rate.toFixed(2)}
                h3Block6={"R" + ExpressRate().DUR.h1B6Rate.toFixed(2)}
                headerFour={"PTA"}
                h4Block2={"R" + ExpressRate().PTA.h1B2Rate.toFixed(2)}
                h4Block3={"R" + ExpressRate().PTA.h1B3Rate.toFixed(2)}
                h4Block4={"R" + ExpressRate().PTA.h1B4Rate.toFixed(2)}
                h4Block5={"R" + ExpressRate().PTA.h1B5Rate.toFixed(2)}
                h4Block6={"R" + ExpressRate().PTA.h1B6Rate.toFixed(2)}
                headerFive={"PMB"}
                h5Block2={"R" + ExpressRate().PMB.h1B2Rate.toFixed(2)}
                h5Block3={"R" + ExpressRate().PMB.h1B3Rate.toFixed(2)}
                h5Block4={"R" + ExpressRate().PMB.h1B4Rate.toFixed(2)}
                h5Block5={"R" + ExpressRate().PMB.h1B5Rate.toFixed(2)}
                h5Block6={"R" + ExpressRate().PMB.h1B6Rate.toFixed(2)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ height: 48 }} />
              <div>
                Only economy rates can be edited the rest can be adjusted by
                percentage marker
              </div>
              <RatesTable
                ratesDisplayAdjuster={ratesDisplayAdjuster}
                title={"ECONOMY ROAD-FREIGHT"}
                headerOne={"JHB"}
                h1Block2={"R" + jhbToJhb.toFixed(2)}
                onClickB2h2={() => setRatesDisplayAdjsuter("JHB to JHB")}
                h1Change2={ratesDisplayAdjuster === "JHB to JHB" ? true : false}
                h1Block3={"R" + jhbToCpt.toFixed(2)}
                onClickB2h3={() => setRatesDisplayAdjsuter("JHB to CPT")}
                h1Change3={ratesDisplayAdjuster === "JHB to CPT" ? true : false}
                h1Block4={"R" + jhbToDur.toFixed(2)}
                onClickB2h4={() => setRatesDisplayAdjsuter("JHB to DUR")}
                h1Change4={ratesDisplayAdjuster === "JHB to DUR" ? true : false}
                h1Block5={"R" + jhbToPta.toFixed(2)}
                onClickB2h5={() => setRatesDisplayAdjsuter("JHB to PTA")}
                h1Change5={ratesDisplayAdjuster === "JHB to PTA" ? true : false}
                h1Block6={"R" + jhbToPmb.toFixed(2)}
                onClickB2h6={() => setRatesDisplayAdjsuter("JHB to PMB")}
                h1Change6={ratesDisplayAdjuster === "JHB to PMB" ? true : false}
                headerTwo={"CPT"}
                h2Block2={"R" + jhbToCpt.toFixed(2)}
                onClickB3h2={() => setRatesDisplayAdjsuter("JHB to CPT")}
                h3Change2={ratesDisplayAdjuster === "JHB to CPT" ? true : false}
                h2Block3={"R" + cptTocpt.toFixed(2)}
                onClickB3h3={() => setRatesDisplayAdjsuter("CPT to CPT")}
                h3Change3={ratesDisplayAdjuster === "CPT to CPT" ? true : false}
                h2Block4={"R" + cptToDur.toFixed(2)}
                onClickB3h4={() => setRatesDisplayAdjsuter("CPT to DUR")}
                h3Change4={ratesDisplayAdjuster === "CPT to DUR" ? true : false}
                h2Block5={"R" + cptToPta.toFixed(2)}
                onClickB3h5={() => setRatesDisplayAdjsuter("CPT to PTA")}
                h3Change5={ratesDisplayAdjuster === "CPT to PTA" ? true : false}
                h2Block6={"R" + cptToPmb.toFixed(2)}
                onClickB3h6={() => setRatesDisplayAdjsuter("CPT to PMB")}
                h3Change6={ratesDisplayAdjuster === "CPT to PMB" ? true : false}
                headerThree={"DUR"}
                h3Block2={"R" + jhbToDur.toFixed(2)}
                onClickB4h2={() => setRatesDisplayAdjsuter("JHB to DUR")}
                h4Change2={ratesDisplayAdjuster === "JHB to DUR" ? true : false}
                h3Block3={"R" + cptToDur.toFixed(2)}
                onClickB4h3={() => setRatesDisplayAdjsuter("CPT to DUR")}
                h4Change3={ratesDisplayAdjuster === "CPT to DUR" ? true : false}
                h3Block4={"R" + durToDur.toFixed(2)}
                onClickB4h4={() => setRatesDisplayAdjsuter("DUR to DUR")}
                h4Change4={ratesDisplayAdjuster === "DUR to DUR" ? true : false}
                h3Block5={"R" + durToPta.toFixed(2)}
                onClickB4h5={() => setRatesDisplayAdjsuter("DUR to PTA")}
                h4Change5={ratesDisplayAdjuster === "DUR to PTA" ? true : false}
                h3Block6={"R" + durToPmb.toFixed(2)}
                onClickB4h6={() => setRatesDisplayAdjsuter("DUR to PMB")}
                h4Change6={ratesDisplayAdjuster === "DUR to PMB" ? true : false}
                headerFour={"PTA"}
                h4Block2={"R" + jhbToPta.toFixed(2)}
                onClickB5h2={() => setRatesDisplayAdjsuter("JHB to PTA")}
                h5Change2={ratesDisplayAdjuster === "JHB to PTA" ? true : false}
                h4Block3={"R" + cptToPta.toFixed(2)}
                onClickB5h3={() => setRatesDisplayAdjsuter("CPT to PTA")}
                h5Change3={ratesDisplayAdjuster === "CPT to PTA" ? true : false}
                h4Block4={"R" + durToPta.toFixed(2)}
                onClickB5h4={() => setRatesDisplayAdjsuter("DUR to PTA")}
                h5Change4={ratesDisplayAdjuster === "DUR to PTA" ? true : false}
                h4Block5={"R" + ptaToPta.toFixed(2)}
                onClickB5h5={() => setRatesDisplayAdjsuter("PTA to PTA")}
                h5Change5={ratesDisplayAdjuster === "PTA to PTA" ? true : false}
                h4Block6={"R" + ptaToPmb.toFixed(2)}
                onClickB5h6={() => setRatesDisplayAdjsuter("PTA to PMB")}
                h5Change6={ratesDisplayAdjuster === "PTA to PMB" ? true : false}
                headerFive={"PMB"}
                h5Block2={"R" + jhbToPmb.toFixed(2)}
                onClickB6h2={() => setRatesDisplayAdjsuter("JHB to PMB")}
                h6Change2={ratesDisplayAdjuster === "JHB to PMB" ? true : false}
                h5Block3={"R" + cptToPmb.toFixed(2)}
                onClickB6h3={() => setRatesDisplayAdjsuter("CPT to PMB")}
                h6Change3={ratesDisplayAdjuster === "CPT to PMB" ? true : false}
                h5Block4={"R" + durToPmb.toFixed(2)}
                onClickB6h4={() => setRatesDisplayAdjsuter("DUR to PMB")}
                h6Change4={ratesDisplayAdjuster === "DUR to PMB" ? true : false}
                h5Block5={"R" + ptaToPmb.toFixed(2)}
                onClickB6h5={() => setRatesDisplayAdjsuter("PTA to PMB")}
                h6Change5={ratesDisplayAdjuster === "PTA to PMB" ? true : false}
                h5Block6={"R" + pmbToPmb.toFixed(2)}
                onClickB6h6={() => setRatesDisplayAdjsuter("PMB to PMB")}
                h6Change6={ratesDisplayAdjuster === "PMB to PMB" ? true : false}
              />
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RatesAdjuster
                  surcharge={surcharge}
                  onChange={(e) => setSurcharge(e.target.value)}
                  setPdfDisplay={setPdfDisplay}
                  setPercentValue={setPercentValue}
                  rateAmount={rateAmount}
                  RateChangerHandler={RateChangerHandler}
                  setRateAmount={setRateAmount}
                  title={ratesDisplayAdjuster}
                  change={ratesDisplayAdjuster}
                  onClickCancel={() => setRatesDisplayAdjsuter("")}
                  setRatesDisplayAdjsuter={setRatesDisplayAdjsuter}
                />
              </div>
              {/* <RatesTable /> */}
            </div>
          </div>
        </div>
      ) : null}
      {rateView === "PALLET RATES" ? (
        <div
          style={{
            paddingTop: 80,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              height: "95%",
              width: "95%",
              boxShadow: "1px 1px 1px 1px #00000033",
              display: "flex",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 0.5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PalletTable
                jhbToCptRate1to4={jhbToCptRate1to4}
                jhbToCptRate5to8={jhbToCptRate5to8}
                jhbToDurRate1to4={jhbToDurRate1to4}
                jhbToDurRate5to8={jhbToDurRate5to8}
                dbnToCptRate1to4={dbnToCptRate1to4}
                dbnToCptRate5to8={dbnToCptRate5to8}
                setDbnToCptRate1to4={setDbnToCptRate1to4}
                setDbnToCptRate5to8={setDbnToCptRate5to8}
                setJhbToCptRate1to4={setJhbToCptRate1to4}
                setJhbToCptRate5to8={setJhbToCptRate5to8}
                setJhbToDurRate1to4={setJhbToDurRate1to4}
                setJhbToDurRate5to8={setJhbToDurRate5to8}
              />
              <div
                style={{
                  height: 200,
                  width: "90%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 0.5,
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    height: "100%",
                  }}
                >
                  <form>
                    <label
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#131f48",
                      }}
                    >
                      fuel surcharge adjuster
                    </label>

                    <input
                      value={palletSurcharge}
                      style={{
                        marginBottom: 10,
                        width: "90%",
                        height: "30px",
                        borderWidth: "1px",
                        borderRadius: 8,
                        color: "#131f48",
                        //   borderStyle: "solid",
                      }}
                      onChange={(e) => setPalletSurcharge(e.target.value)}
                      type="text"
                    />
                  </form>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 0.5,
                    justifyContent: "center",
                    alignItems: "center",

                    height: "100%",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      fontWeight: "bold",
                      padding: 10,
                      borderRadius: 8,
                      marginTop: 20,
                    }}
                    onClick={() => setPdfDisplay(true)}
                  >
                    PREVIEW
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* // end pref rates */}
      {pdfDisplay ? (
        rateView === "PALLET RATES" ? (
          <div
            style={{
              position: "absolute",
              overflow: "scroll",
              width: "100%",
              backgroundColor: "#00000033",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: height,
              zIndex: 10,
              // top: 0,s
            }}
          >
            <div
              style={{
                top: 0,
                width: 600,
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 0px 5px 5px #00000033",
                height: height,
              }}
            >
              <div
                id="palletreport"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: Colors.white,
                  position: "relative",
                }}
              >
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/header.png")}
                  style={{ width: "100%", marginBottom: 10 }}
                />
                <div
                  style={{
                    position: "absolute",
                    left: "65%",
                    top: 60,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 10, fontWeight: "bold" }}>
                    Agent Name: {agentName}
                  </div>
                  <div
                    style={{ marginTop: 10, fontSize: 10, fontWeight: "bold" }}
                  >
                    Customer: {customerName}
                  </div>
                </div>
                <PalletTable
                  changeHeight={true}
                  pdfItem
                  jhbToCptRate1to4={Number(jhbToCptRate1to4).toFixed(2)}
                  jhbToCptRate5to8={Number(jhbToCptRate5to8).toFixed(2)}
                  jhbToDurRate1to4={Number(jhbToDurRate1to4).toFixed(2)}
                  jhbToDurRate5to8={Number(jhbToDurRate5to8).toFixed(2)}
                  dbnToCptRate1to4={Number(dbnToCptRate1to4).toFixed(2)}
                  dbnToCptRate5to8={Number(dbnToCptRate5to8).toFixed(2)}
                />
                <div style={{ fontSize: 12, width: "80%", marginTop: 8 }}>
                  Fuel surcharge: {palletSurcharge} %
                </div>
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/palletTerms.png")}
                  style={{
                    width: "80%",
                    backgroundColor: Colors.white,
                    // marginBottom: 8,
                  }}
                />
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/footer.png")}
                  style={{
                    width: "100%",
                    backgroundColor: Colors.white,
                    marginTop: 5,
                  }}
                />
                <button
                  style={{
                    position: "fixed",
                    left: "25%",
                    bottom: 100,
                    height: 80,
                    width: 80,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    fontWeight: "bold",
                  }}
                  onClick={() => generatePalletPDF()}
                  type="button"
                >
                  Export PDF
                </button>
                <button
                  style={{
                    position: "fixed",
                    left: "25%",
                    bottom: 200,
                    height: 80,
                    width: 80,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                    fontWeight: "bold",
                  }}
                  onClick={() => setPdfDisplay(false)}
                  type="button"
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              overflow: "scroll",
              width: "100%",
              backgroundColor: "#00000033",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: height,
              zIndex: 10,
              // top: 0,s
            }}
          >
            <div
              style={{
                top: 0,
                width: 600,
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 0px 5px 5px #00000033",
                height: height,
              }}
            >
              <div
                id="report"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: Colors.white,
                  position: "relative",
                }}
              >
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/header.png")}
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    left: "65%",
                    top: 60,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontSize: 10, fontWeight: "bold" }}>
                    Agent Name: {agentName}
                  </div>
                  <div
                    style={{ marginTop: 10, fontSize: 10, fontWeight: "bold" }}
                  >
                    Customer: {customerName}
                  </div>
                </div>
                <RatesTable
                  type={true}
                  title={"OVERNIGHT ROAD-FREIGHT"}
                  headerOne={"JHB"}
                  h1Block2={"R" + (2.0).toFixed(2)}
                  h1Block3={"R" + (13.63).toFixed(2)}
                  h1Block4={"R" + (4.5).toFixed(2)}
                  h1Block5={"R" + (5.1).toFixed(2)}
                  h1Block6={"R" + (5.55).toFixed(2)}
                  headerTwo={"CPT"}
                  h2Block2={"R" + (13.63).toFixed(2)}
                  h2Block3={"R" + (2.0).toFixed(2)}
                  h2Block4={"request"}
                  h2Block5={"R" + (14.1).toFixed(2)}
                  h2Block6={"request"}
                  headerThree={"DUR"}
                  h3Block2={"R" + (4.5).toFixed(2)}
                  h3Block3={"request"}
                  h3Block4={"R" + (2.0).toFixed(2)}
                  h3Block5={"R" + (5.55).toFixed(2)}
                  h3Block6={"R" + (2.5).toFixed(2)}
                  headerFour={"PTA"}
                  h4Block2={"R" + (5.1).toFixed(2)}
                  h4Block3={"R" + (14.1).toFixed(2)}
                  h4Block4={"R" + (5.55).toFixed(2)}
                  h4Block5={"R" + (2.0).toFixed(2)}
                  h4Block6={"R" + (6.5).toFixed(2)}
                  headerFive={"PMB"}
                  h5Block2={"R" + (5.55).toFixed(2)}
                  h5Block3={"request"}
                  h5Block4={"R" + (2.5).toFixed(2)}
                  h5Block5={"R" + (6.5).toFixed(2)}
                  h5Block6={"R" + (2.0).toFixed(2)}
                />
                <div
                  style={{ fontSize: 10, textAlign: "center", marginBottom: 2 }}
                >
                  The minimum rate is R280.00 central areas, is applicable for
                  2kg per consignment
                </div>
                {/* <div style={{ height: 0 }} /> */}
                <RatesTable
                  type={true}
                  title={"EXPRESS ROAD-FREIGHT"}
                  headerOne={"JHB"}
                  h1Block2={"R" + ExpressRate().JHB.h1B2Rate.toFixed(2)}
                  h1Block3={"R" + ExpressRate().JHB.h1B3Rate.toFixed(2)}
                  h1Block4={"R" + ExpressRate().JHB.h1B4Rate.toFixed(2)}
                  h1Block5={"R" + ExpressRate().JHB.h1B5Rate.toFixed(2)}
                  h1Block6={"R" + ExpressRate().JHB.h1B6Rate.toFixed(2)}
                  headerTwo={"CPT"}
                  h2Block2={"R" + ExpressRate().CPT.h1B2Rate.toFixed(2)}
                  h2Block3={"R" + ExpressRate().CPT.h1B3Rate.toFixed(2)}
                  h2Block4={"R" + ExpressRate().CPT.h1B4Rate.toFixed(2)}
                  h2Block5={"R" + ExpressRate().CPT.h1B5Rate.toFixed(2)}
                  h2Block6={"R" + ExpressRate().CPT.h1B6Rate.toFixed(2)}
                  headerThree={"DUR"}
                  h3Block2={"R" + ExpressRate().DUR.h1B2Rate.toFixed(2)}
                  h3Block3={"R" + ExpressRate().DUR.h1B3Rate.toFixed(2)}
                  h3Block4={"R" + ExpressRate().DUR.h1B4Rate.toFixed(2)}
                  h3Block5={"R" + ExpressRate().DUR.h1B5Rate.toFixed(2)}
                  h3Block6={"R" + ExpressRate().DUR.h1B6Rate.toFixed(2)}
                  headerFour={"PTA"}
                  h4Block2={"R" + ExpressRate().PTA.h1B2Rate.toFixed(2)}
                  h4Block3={"R" + ExpressRate().PTA.h1B3Rate.toFixed(2)}
                  h4Block4={"R" + ExpressRate().PTA.h1B4Rate.toFixed(2)}
                  h4Block5={"R" + ExpressRate().PTA.h1B5Rate.toFixed(2)}
                  h4Block6={"R" + ExpressRate().PTA.h1B6Rate.toFixed(2)}
                  headerFive={"PMB"}
                  h5Block2={"R" + ExpressRate().PMB.h1B2Rate.toFixed(2)}
                  h5Block3={"R" + ExpressRate().PMB.h1B3Rate.toFixed(2)}
                  h5Block4={"R" + ExpressRate().PMB.h1B4Rate.toFixed(2)}
                  h5Block5={"R" + ExpressRate().PMB.h1B5Rate.toFixed(2)}
                  h5Block6={"R" + ExpressRate().PMB.h1B6Rate.toFixed(2)}
                />
                <div style={{ fontSize: 10, textAlign: "center" }}>
                  The minimum rate is R140.00 central areas, is applicable for
                  20kg per consignment
                </div>
                <div style={{ height: 19.7 }} />
                <RatesTable
                  type={true}
                  ratesDisplayAdjuster={ratesDisplayAdjuster}
                  title={"ECONOMY ROAD-FREIGHT"}
                  headerOne={"JHB"}
                  h1Block2={"R" + jhbToJhb.toFixed(2)}
                  h1Block3={"R" + jhbToCpt.toFixed(2)}
                  h1Block4={"R" + jhbToDur.toFixed(2)}
                  h1Block5={"R" + jhbToPta.toFixed(2)}
                  h1Block6={"R" + jhbToPmb.toFixed(2)}
                  headerTwo={"CPT"}
                  h2Block2={"R" + jhbToCpt.toFixed(2)}
                  h2Block3={"R" + cptTocpt.toFixed(2)}
                  h2Block4={"R" + cptToDur.toFixed(2)}
                  h2Block5={"R" + cptToPta.toFixed(2)}
                  h2Block6={"R" + cptToPmb.toFixed(2)}
                  headerThree={"DUR"}
                  h3Block2={"R" + jhbToDur.toFixed(2)}
                  h3Block3={"R" + cptToDur.toFixed(2)}
                  h3Block4={"R" + durToDur.toFixed(2)}
                  h3Block5={"R" + durToPta.toFixed(2)}
                  h3Block6={"R" + durToPmb.toFixed(2)}
                  headerFour={"PTA"}
                  h4Block2={"R" + jhbToPta.toFixed(2)}
                  h4Block3={"R" + cptToPta.toFixed(2)}
                  h4Block4={"R" + durToPta.toFixed(2)}
                  h4Block5={"R" + ptaToPta.toFixed(2)}
                  h4Block6={"R" + ptaToPmb.toFixed(2)}
                  headerFive={"PMB"}
                  h5Block2={"R" + jhbToPmb.toFixed(2)}
                  h5Block3={"R" + cptToPmb.toFixed(2)}
                  h5Block4={"R" + durToPmb.toFixed(2)}
                  h5Block5={"R" + ptaToPmb.toFixed(2)}
                  h5Block6={"R" + pmbToPmb.toFixed(2)}
                />
                <div style={{ fontSize: 10, textAlign: "center" }}>
                  The minimum rate is R90.00 central areas, is applicable for
                  10kg per consignment
                </div>
                {/* <div style={{ height: 16 }} /> */}
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/footer.png")}
                  style={{ width: "100%", backgroundColor: Colors.white }}
                />

                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/header.png")}
                  style={{
                    width: "100%",
                    backgroundColor: Colors.white,
                    marginBottom: 10,
                  }}
                />
                <div>Fuel surcharge: {surcharge} %</div>
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/page2.png")}
                  style={{
                    width: "85%",
                    backgroundColor: Colors.white,
                    marginBottom: 8,
                  }}
                />
                <img
                  alt="loading"
                  src={require("../../assets/pdf-images/footer.png")}
                  style={{ width: "100%", backgroundColor: Colors.white }}
                />
              </div>
              <button
                style={{
                  position: "fixed",
                  left: "25%",
                  bottom: 100,
                  height: 80,
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  fontWeight: "bold",
                }}
                onClick={() => generatePDF()}
                type="button"
              >
                Export PDF
              </button>
              <button
                style={{
                  position: "fixed",
                  left: "25%",
                  bottom: 200,
                  height: 80,
                  width: 80,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  fontWeight: "bold",
                }}
                onClick={() => setPdfDisplay(false)}
                type="button"
              >
                CLOSE
              </button>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};
export default Rates;
