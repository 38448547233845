import React, { useState } from "react";
import { Colors } from "../../colors/Colors";
import {
  AnimationBanner,
  BeeCert,
  ClientPortalButton,
  ContactUsButton,
  LearningComponent,
  LoginOptions,
  QouteComponent,
  ServicesButton,
  SocialButton,
  useWindowDimensions,
  WhyChooseComponent,
} from "../../components";
import "./main_page.css";

const servicesData = [
  {
    title: "OVERNIGHT",
    image: require("../../assets/overnightTruck.png"),
    infoTitle: "Overnight Road Express",
    titleOne: "Johannesburg > Cape Town > Johannesburg:",
    descriptionOne:
      "Collections are done Monday – Thursday with delivery done before 15:00 the following business day.",
    titleTwo: "Johannesburg > Durban > Johannesburg:",

    descriptionTwo:
      "Collections are done Monday – Thursday with delivery done before 11:00 the following business day.",
    titleThree: "Cape Town > Durban > Cape Town:",
    descriptionThree: "This service is on request, airfreight is an option.",
  },
  {
    title: "EXPRESS",
    image: require("../../assets/expresstruck.png"),
    infoTitle: "Conventional, timely road freight",
    titleOne: "Johannesburg > Cape Town > Johannesburg:",
    descriptionOne:
      "Collections are done Monday – Friday with delivery done within 2 business days before 15:00 the second business day.",

    titleTwo: "Johannesburg > Durban > Johannesburg:",
    descriptionTwo:
      "Collections are done Monday – Friday with delivery done before 15:00 the following business day.",

    titleThree: "Cape Town > Durban > Cape Town:",
    descriptionThree:
      "Collections are done Monday – Friday with delivery done within 2 business days before 15:00 the second business day.",

    titleFour: "Saturday Deliveries:",
    descriptionFour: "On request.",
  },
  {
    title: "ECONOMY",
    image: require("../../assets/economy.png"),
    infoTitle: "Cost effective Road Freight",
    titleOne: "Johannesburg > Cape Town > Johannesburg:",
    descriptionOne:
      "Collections are done Monday – Friday with delivery done within 2-4 business days.",
    titleTwo: "Johannesburg > Durban > Johannesburg:",
    descriptionTwo:
      "Collections are done Monday – Friday with delivery done within 1-3 business days.",
    titleThree: "Cape Town > Durban > Cape Town:",

    descriptionThree:
      "Collections are done Monday – Friday with delivery done within 2-4 business days.",
  },
  {
    title: "PALLET",
    image: require("../../assets/palletLift.png"),
    infoTitle: "Palletized Road Freight",
    titleOne: "Johannesburg > Cape Town > Johannesburg:",
    descriptionOne:
      "Collections are done Monday – Friday with delivery done within 2-3 business days",
    titleTwo: "Johannesburg > Durban > Johannesburg:",

    descriptionTwo:
      "Collections are done Monday – Friday with delivery done within 1-2 business days.",
    titleThree: "Cape Town > Durban > Cape Town:",

    descriptionThree:
      "Collections are done Monday – Friday with delivery done within 2-4 business days.",
  },
  {
    title: "FULL-LOADS",
    image: require("../../assets/fullLoads.png"),
    infoTitle: "Dedicated truck",
    description:
      "Full and partial loads done on request according to your business needs via 6 meter, 12 meter and super link trucks.",
  },
  {
    title: "Warehousing",
    image: require("../../assets/warehousing.png"),
    infoTitle: "Warehousing",
    description:
      "Warehousing and distribution service for efficient inventory management.",
  },
];

const MainPage = () => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [indexInfo, setIndexInfo] = useState(0);
  const [quoteBanner, setQuoteBanner] = useState(false);
  const [closeHover, setCloseHover] = useState(false);
  const [bannerQuoteAnim, setBannerQuoteAnim] = useState(false);
  const { width, height } = useWindowDimensions();

  const heartBeat = () => {
    setBannerQuoteAnim(true);
    setTimeout(() => {
      setBannerQuoteAnim(false);
    }, 100);
    setTimeout(() => {
      setBannerQuoteAnim(true);
    }, 200);
  };

  const onInfoClick = () => {
    setMoreInfo(true);

    if (animationTrigger === true) {
      setAnimationTrigger(false);
    }

    setTimeout(
      () => {
        setAnimationTrigger(true);
      },
      moreInfo ? 1000 : 100
    );
  };

  return (
    <div
      style={{
        display: "flex ",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/GTDLogo.png")}
            className="App-logo"
            alt="logo"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img
            src={require("../../assets/TitleHeader.png")}
            className="title-banner-spec"
            alt="Loading banner"
          />
          <ClientPortalButton />
        </div>
        <div
          onClick={() => setQuoteBanner(true)}
          onMouseOver={() => heartBeat(true)}
          onMouseOut={() => setBannerQuoteAnim(false)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <img
            src={require("../../assets/NeedQuotePanel.png")}
            className="quote-banner"
            alt="Loading banner"
            style={{
              height: bannerQuoteAnim ? 90 : null,
              width: bannerQuoteAnim ? "120%" : null,
              // position: "absolute",
              bottom: -80,
              transition: "all 0.2s",
            }}
          />
        </div>
        <QouteComponent
          quoteBanner={quoteBanner}
          setQuoteBanner={setQuoteBanner}
          width={width}
        />

        <WhyChooseComponent quoteBanner={quoteBanner} width={width} />
        <LearningComponent width={width} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#00000",
            width: "100%",
            height: "80px",
          }}
        >
          <img
            src={require("../../assets/servicesbanner.png")}
            className="services-banner"
            alt="Loading banner"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            //   backgroundColor: "#000000",
            width: "100%",
            //   height: "80px",
            overflow: "scroll",
          }}
        >
          <div style={{ flex: 0.17, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONOvernightFULL.png")}
              title={"OVERNIGHT"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(0);
              }}
            />
          </div>
          <div style={{ flex: 0.16, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONExpressFULL.png")}
              title={"EXPRESS"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(1);
              }}
            />
          </div>
          <div style={{ flex: 0.16, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONEconomyFULL.png")}
              title={"ECONOMY"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(2);
              }}
            />
          </div>
          <div style={{ flex: 0.16, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONPalletFULL.png")}
              title={"PALLET"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(3);
              }}
            />
          </div>

          <div style={{ flex: 0.16, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONFullLoadsFULL.png")}
              title={"FULL LOADS"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(4);
              }}
            />
          </div>

          <div style={{ flex: 0.16, height: "200px" }}>
            <ServicesButton
              source={require("../../assets/icons/ICONWarehousingFULL.png")}
              title={"WAREHOUSING"}
              onClick={() => {
                onInfoClick();
                setIndexInfo(5);
              }}
            />
          </div>
        </div>
        <div
          style={{
            height: moreInfo ? "500px" : 0,
            transition: "height 1.5s",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {moreInfo ? (
            <div
              style={{
                // width: "100%",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 20,
                  top: 140,
                  cursor: "pointer",
                  color: "#131f48",
                  fontWeight: "bold",
                  transition: "all 0.2s",
                  fontSize: closeHover ? 24 : 18,
                }}
                onClick={() => setMoreInfo(false)}
                onMouseOver={() => setCloseHover(true)}
                onMouseOut={() => setCloseHover(false)}
              >
                CLOSE
              </div>
              <AnimationBanner
                imageSet={servicesData[indexInfo].image}
                title={servicesData[indexInfo].title}
                animationTrigger={animationTrigger}
                value={indexInfo}
              />
              {indexInfo === 0 ||
              indexInfo === 1 ||
              indexInfo === 2 ||
              indexInfo === 3 ? (
                <>
                  <div
                    style={{
                      height: "400px",
                      width: "100%",
                      flexDirection: "column",
                      display: "flex",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    <div
                      style={{
                        color: "#00ABD6",
                        fontSize: "26px",
                        fontWeight: "bold",
                        marginTop: width > 599 ? "20px" : "40px",
                      }}
                    >
                      {servicesData[indexInfo].infoTitle}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        {servicesData[indexInfo].titleOne}
                      </div>
                      {servicesData[indexInfo].descriptionOne}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        {servicesData[indexInfo].titleTwo}
                      </div>
                      {servicesData[indexInfo].descriptionTwo}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                        }}
                      >
                        {servicesData[indexInfo].titleThree
                          ? servicesData[indexInfo].titleThree
                          : null}
                      </div>
                      {servicesData[indexInfo].descriptionThree
                        ? servicesData[indexInfo].descriptionThree
                        : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "20px",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {servicesData[indexInfo].titleFour
                          ? servicesData[indexInfo].titleFour
                          : null}
                      </div>
                      {servicesData[indexInfo].descriptionFour
                        ? servicesData[indexInfo].descriptionFour
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
              {indexInfo === 4 ? (
                <>
                  <div
                    style={{
                      color: "#00ABD6",
                      fontSize: "26px",
                      fontWeight: "bold",
                      marginTop: "20px",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    {servicesData[indexInfo].infoTitle}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: "20px",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    {servicesData[indexInfo].description}
                  </div>
                </>
              ) : null}
              {indexInfo === 5 ? (
                <>
                  <div
                    style={{
                      color: "#00ABD6",
                      fontSize: "26px",
                      fontWeight: "bold",
                      marginTop: "20px",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    {servicesData[indexInfo].infoTitle}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: "20px",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    {servicesData[indexInfo].description}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: "20px",
                      flexDirection: "column",
                      marginLeft: width > 599 ? "40px" : "5px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "10px",
                        marginLeft: width > 599 ? "40px" : "5px",
                      }}
                    >
                      This includes:
                    </div>
                    <div style={{ marginLeft: width > 599 ? "50px" : "5px" }}>
                      - Order fulfilment
                    </div>
                    <div style={{ marginLeft: width > 599 ? "50px" : "5px" }}>
                      - Collection
                    </div>
                    <div style={{ marginLeft: width > 599 ? "50px" : "5px" }}>
                      - Destuﬃng of containers from ports and manufacturers
                    </div>
                    <div style={{ marginLeft: width > 599 ? "50px" : "5px" }}>
                      - Precise Picking and Palletizing
                    </div>
                    <div style={{ marginLeft: width > 599 ? "50px" : "5px" }}>
                      - 24 hour camera surveillance
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          className="content-container"
          style={{
            flexDirection: width > 599 ? "row" : "column",
            height: width > 599 ? "450px" : null,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 0.33,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#00ABD6",
                fontSize: "26px",
                fontWeight: "bold",
                marginTop: "20px",
                width: "80%",
                marginLeft: "40px",
              }}
            >
              ABOUT GTD
            </div>
            <div
              style={{
                marginTop: "20px",
                width: "80%",
                color: "#FFFFFF",
                fontSize: 14,
                marginLeft: "40px",
              }}
            >
              GTD Express has evolved into one of the leading Road Freight
              Distribution Companies in South Africa, basing our success on
              making use of our own vehicles which ensures us to have proper
              controls, time management and service delivery.
            </div>
            <div
              style={{
                color: "#00ABD6",
                fontSize: "26px",
                fontWeight: "bold",
                marginTop: "20px",
                width: "80%",
                marginLeft: "40px",
              }}
            >
              Social Media Links:
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                marginTop: 20,
              }}
            >
              <SocialButton
                pic={require("../../assets/icons/FaceBook.png")}
                link="https://www.facebook.com/profile.php?id=100093048591981&mibextid=LQQJ4d"
              />
              <SocialButton
                pic={require("../../assets/icons/insta.png")}
                link="https://instagram.com/gtd_southafrica?igshid=OGQ5ZDc2ODk2ZA=="
              />
              <SocialButton
                pic={require("../../assets/icons/linkedin.png")}
                link="https://www.linkedin.com/company/gtd-express/"
              />
              <SocialButton
                pic={require("../../assets/icons/whatsapp.png")}
                link="https://wa.me/27602096819"
              />
            </div>
          </div>
          <div
            style={{
              flex: 0.34,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#00ABD6",
                fontSize: "26px",
                fontWeight: "bold",
                marginTop: "20px",
                width: width > 599 ? "80%" : "100%",
              }}
            >
              CONTACT DETAILS
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 599 ? "80%" : "100%",
                marginTop: "20px",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: "#FFFFFF", fontSize: 12 }}
              >
                JOHANNESBURG
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Freight City, Stand 597, Ext 38, Innes Road, Jet Park, Gauteng
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Tel: 011 974 2271
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Fax: 011 974 8555
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 599 ? "80%" : "100%",
                marginTop: "20px",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: "#FFFFFF", fontSize: 12 }}
              >
                DURBAN
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Unit 5 Transport Park, 9 Otto Volek Road, New Germany, KZN
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Tel: 031 700 2632 / 031 700 2568
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Fax: 031 700 1879
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 599 ? "80%" : "100%",
                marginTop: "20px",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: "#FFFFFF", fontSize: 12 }}
              >
                CAPE TOWN
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Unit E, Golf Air Park, Airport Industria, Cape Town, Western
                Cape
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Tel: 021 982 2255
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Fax: 021 982 2043
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: width > 599 ? "80%" : "100%",
                marginTop: "20px",
              }}
            >
              <div
                style={{ fontWeight: "bold", color: "#FFFFFF", fontSize: 12 }}
              >
                After hours contact details
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Jhb: 061 423 9162
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Durban: 081 500 9632
              </div>
              <div
                style={{ fontWeight: "500", color: "#FFFFFF", fontSize: 12 }}
              >
                Cape town: 081 712 0003
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 0.33,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#00ABD6",
                fontSize: "26px",
                fontWeight: "bold",
                marginTop: "20px",
                width: width > 599 ? "80%" : "100%",
              }}
            >
              PHYSICAL ADDRESS
            </div>
            <a href="https://www.google.com/maps/place/Vanacht+St,+Isando,+Kempton+Park,+1600/@-26.126673,28.217484,15z/data=!4m6!3m5!1s0x1e95147ad5a66eb3:0xba6c9e260790448f!8m2!3d-26.126673!4d28.217484!16s%2Fg%2F1tqq0j6y?hl=en&entry=ttu">
              <img
                src={require("../../assets/location.png")}
                alt="loading"
                style={{
                  marginTop: "20px",
                  marginBottom: width > 599 ? "0px" : "20px",
                }}
              />
            </a>

            {/* https://www.google.com/maps/place/Vanacht+St,+Isando,+Kempton+Park,+1600/@-26.126673,28.217484,15z/data=!4m6!3m5!1s0x1e95147ad5a66eb3:0xba6c9e260790448f!8m2!3d-26.126673!4d28.217484!16s%2Fg%2F1tqq0j6y?hl=en&entry=ttu */}
          </div>
        </div>
        <div
          style={{
            height: "40px",
            width: "100%",
            backgroundColor: "#00ABD6",
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Colors.white,
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          Built by Geek-Kit
        </div>
        <BeeCert />
        <LoginOptions width={width} height={height} />
        <ContactUsButton />
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default MainPage;
