import React, { useState } from "react";
// import { Colors } from "../../colors/Colors";
import {  useWindowDimensions } from "../../components";
import StandardButton from "./components/StandardButton";

const Tutorials = () => {
  const { width, height } = useWindowDimensions();

  return (
    <div
      style={{
        width: width,
        overflow: "hidden",
        backgroundColor: "#FFFFFF",
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
   <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          position: "fixed",
          backgroundColor: "#FFFFFF",
          boxShadow: "1px 1px 0px 0px #00000033",
          top: 0,
        }}
      >
        <img
          src={require("../../assets/GTDLogostandard.png")}
          style={{ height: 60, width: 140, marginLeft: 20 }}
          alt="logo"
        />
        <h1 style={{ marginLeft: 20, color: "#00ABD6", fontSize: 28 }}>
          Tutorials
        </h1>
      </div>
      <div>
        <a href="https://youtu.be/rn1tGXCZsuw">
        <StandardButton title={"LOGIN"} />
        </a>
        <a href="https://youtu.be/ZULA-TzKvOo">
        <StandardButton title={"FORGOT PASSWORD"} />
        </a>
        <a href="https://youtu.be/gzFjegg5RDQ">
        <StandardButton title={"SIGN UP"} />
        </a>
        
        </div>
    </div>
  );
};

export default Tutorials;
