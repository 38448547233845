import React, { useState } from "react";
import { Colors } from "../../../../colors/Colors";
import HeaderRow from "../header/HeaderRow";
import TablePalletRow from "../table-pallet-row/TablePalletRow";

const PalletTable = ({
  changeHeight,
  pdfItem,
  jhbToCptRate1to4,
  jhbToCptRate5to8,
  jhbToDurRate1to4,
  jhbToDurRate5to8,
  dbnToCptRate1to4,
  dbnToCptRate5to8,
  setDbnToCptRate1to4,
  setDbnToCptRate5to8,
  setJhbToCptRate1to4,
  setJhbToCptRate5to8,
  setJhbToDurRate1to4,
  setJhbToDurRate5to8,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: changeHeight ? 200 : 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <HeaderRow
        colorBack={Colors.primary}
        line1={"Origin/ Destination"}
        line2={"Quantity"}
        line3={"Amount"}
      />
      <TablePalletRow
        line1={"JHB - DBN - JHB"}
        line2={"1 - 4 Pallets"}
        check
        amount={jhbToDurRate1to4}
        onChange={pdfItem ? null : (e) => setJhbToDurRate1to4(e.target.value)}
      />
      <TablePalletRow
        line2={"5 - 8 Pallets"}
        check
        amount={jhbToDurRate5to8}
        onChange={pdfItem ? null : (e) => setJhbToDurRate5to8(e.target.value)}
      />
      <TablePalletRow />
      <TablePalletRow
        line1={"JHB - CPT - JHB"}
        line2={"1 - 4 Pallets"}
        check
        amount={jhbToCptRate1to4}
        onChange={pdfItem ? null : (e) => setJhbToCptRate1to4(e.target.value)}
      />
      <TablePalletRow
        line2={"5 - 8 Pallets"}
        check
        amount={jhbToCptRate5to8}
        onChange={pdfItem ? null : (e) => setJhbToCptRate5to8(e.target.value)}
      />
      <TablePalletRow />
      <TablePalletRow
        line1={"DBN - CPT - DBN"}
        line2={"1 - 4 Pallets"}
        check
        amount={dbnToCptRate1to4}
        onChange={pdfItem ? null : (e) => setDbnToCptRate1to4(e.target.value)}
      />
      <TablePalletRow
        line2={"5 - 8 Pallets"}
        check
        amount={dbnToCptRate5to8}
        onChange={pdfItem ? null : (e) => setDbnToCptRate5to8(e.target.value)}
      />
    </div>
  );
};

export default PalletTable;
