import React, { useState } from "react";

const OptionsButton = ({ title, onClick }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick}
      style={{
        height: hover ? 50 : 40,
        width: hover ? 220 : 200,
        color: "#FFFFFF",
        backgroundColor: hover ? "#00ABD6" : "#131f48",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        transition: "all 0.1s",
        cursor: "pointer",
      }}
    >
      {title}
    </div>
  );
};
export default OptionsButton;
