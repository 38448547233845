import React, { useState } from "react";
import { Colors } from "../../../colors/Colors";

const Checkbox = ({ title, change, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        height: 40,
        width: 40,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        onClick={onClick}
        style={{
          height: 20,
          width: 20,
          backgroundColor: change
            ? Colors.primary
            : hover
            ? "#00000033"
            : "#FFFFFF",
          borderStyle: "solid",
          borderColor: change
            ? Colors.secondary
            : hover
            ? Colors.secondary
            : Colors.primary,
          borderRadius: "100%",
        }}
      ></div>
      <div style={{ fontWeight: "bold", fontSize: 8 }}>{title}</div>
    </div>
  );
};
export default Checkbox;
