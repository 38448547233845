import React, { useState } from "react";

const ServicesButton = ({ source, title, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
      onClick={onClick}
    >
      <img
        src={source}
        style={{
          maxHeight: hover ? "200px" : "180px",
          opacity: hover ? 0.5 : 1,
          transition: "all 0.2s",
        }}
        alt="Loading"
      />

      <div
        style={{
          fontWeight: "bold",
          color: "#131F48",
          fontSize: "24px",
          position: "absolute",
          bottom: 0,
        }}
      >
        {hover ? title : null}
      </div>
    </div>
  );
};

export default ServicesButton;
