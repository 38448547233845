import React, { useState } from "react";
import { Colors } from "../../colors/Colors";
import { Link } from "react-router-dom";

const LearningComponent = ({ width }) => {
  const [hover, setHover] = useState("");
  return (
    <div
      style={{
        height: width <= 899 ? 600 : 200,
        width: width <= 899 ? "100%" : null,
        display: "flex",
        flexDirection: width <= 899 ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 0.5,
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          onMouseOver={() => setHover("faq")}
          onMouseOut={() => setHover("")}
          to="/Faq"
          style={{
            width: "80%",
            height: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            backgroundColor:
              hover === "faq" ? Colors.primary : Colors.secondary,
            textDecoration: "none",
            transition: "all 0.5s",
            cursor: "pointer",
          }}
        >
          <div>
            <h
              style={{
                fontSize: width <= 899 ? "20px" : "32px",
                fontWeight: "bold",
                //   color: "#00ABD6",
                color: "#FFFFFF",
                marginBottom: "10px",
              }}
            >
              GOT SOME QUESTIONS?
            </h>
            <p style={{ fontWeight: "bold", color: "#FFFFFF" }}>
              Click here to see if we have the answers (FAQ's)
            </p>
          </div>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 0.5,
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
        
         onMouseOver={() => setHover("tut")}
         onMouseOut={() => setHover("")}
         to="/Tuts"
         style={{
           width: "80%",
           height: "80%",
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           alignItems: "center",
           borderRadius: 8,
           backgroundColor:
             hover === "tut" ? Colors.primary : Colors.secondary,
           textDecoration: "none",
           transition: "all 0.5s",
           cursor: "pointer",
         }}
        >
          <h
            style={{
              fontSize: width <= 899 ? "20px" : "32px",
              fontWeight: "bold",
              //   color: "#00ABD6",
              color: "#FFFFFF",
              marginBottom: "10px",
            }}
          >
            NEED SOME HELP?
          </h>
          <p style={{ fontWeight: "bold", color: "#FFFFFF" }}>
            CLICK HERE FOR MORE
          </p>
        </Link>
      </div>
    </div>
  );
};

export default LearningComponent;
