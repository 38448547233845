import React, { useState } from "react";
import { Colors } from "../../colors/Colors";

const BeeCert = () => {
  const [hover, setHover] = useState(false);
  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("BEE.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "BEE.pdf";
        alink.click();
      });
    });
  };
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        position: "absolute",
        top: 80,
        left: 20,
        height: hover ? 100 : 50,
        width: 180,
        backgroundColor: Colors.primary,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: 10,
        transition: "all 0.3s",
      }}
    >
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          fontWeight: "bold",
          color: Colors.white,
        }}
      >
        BBBEE level 2
      </div>
      <div
        style={{
          height: hover ? 80 : 0,
          width: "80%",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors.white,
          borderRadius: 8,
          marginBottom: hover ? 10 : 0,
        }}
      >
        <button onClick={onButtonClick}>Download PDF</button>
      </div>
    </div>
  );
};

export default BeeCert;
