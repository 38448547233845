import React, { useState } from "react";
import SocialButton from "../social-button/SocialButton";

const ContactUsButton = () => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        position: "fixed",
        top: 20,
        left: 20,
        display: "flex",
        padding: 10,
        backgroundColor: "#131f48",
        borderRadius: 10,
        transition: "all 0.3s",
        width: hover ? 160 : 160,
        height: hover ? 200 : 30,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {hover ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              color: "#FFFFFF",
              flex: 0.2,
            }}
          >
            CONTACT US
          </div>
          <div
            style={{
              display: "flex",
              height: 160,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SocialButton
                pic={require("../../assets/icons/FaceBook.png")}
                link="https://www.facebook.com/profile.php?id=100093048591981&mibextid=LQQJ4d"
              />
              <SocialButton
                pic={require("../../assets/icons/insta.png")}
                link="https://instagram.com/gtd_southafrica?igshid=OGQ5ZDc2ODk2ZA=="
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "100%",
              }}
            >
              <SocialButton
                pic={require("../../assets/icons/linkedin.png")}
                link="https://www.linkedin.com/company/gtd-express/"
              />
              <SocialButton
                pic={require("../../assets/icons/whatsapp.png")}
                link="https://wa.me/27602096819"
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "#FFFFFF",
          }}
        >
          CONTACT US
        </div>
      )}
    </div>
  );
};
export default ContactUsButton;
