import React from "react";
import emailjs from "emailjs-com";

export default function EmailComponent({ setFormsDrop }) {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPALTE_ID_REP,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormsDrop(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <form
      className="contact-form"
      onSubmit={sendEmail}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "300px",
      }}
    >
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Company name
      </label>
      <input
        style={{
          marginBottom: 10,
          width: "100%",
          height: "30px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        type="text"
        name="company_name"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Contact person
      </label>
      <input
        style={{
          marginBottom: 10,
          width: "100%",
          height: "30px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        type="text"
        name="user_name"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Address
      </label>
      <textarea
        style={{
          marginBottom: 10,
          width: "100%",
          height: "60px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        name="address"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Email
      </label>
      <input
        style={{
          marginBottom: 10,
          width: "100%",
          height: "30px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        type="email"
        name="user_email"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Cell Number
      </label>
      <input
        style={{
          marginBottom: 10,
          width: "100%",
          height: "30px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        type="text"
        name="cell_number"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Landline
      </label>
      <input
        style={{
          marginBottom: 10,
          width: "100%",
          height: "30px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        type="text"
        name="landline"
      />
      <label style={{ marginBottom: 10, fontWeight: "bold", color: "#131f48" }}>
        Information you require sales representative to present to you
      </label>
      <textarea
        style={{
          marginBottom: 10,
          width: "100%",
          height: "60px",
          borderWidth: "2px",
          borderRadius: 8,
          color: "#131f48",
          //   borderStyle: "solid",
        }}
        name="message"
      />
      <input
        style={{
          height: "60px",
          borderRadius: "10px",
          backgroundColor: "#131f48",
          color: "#FFFFFF",
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "20px",
        }}
        type="submit"
        value="Send"
      />
    </form>
  );
}

// - company name
// - Address
// -  name
// -  landline number
// -  Cell number
// -  information you require sales representative to present to you
