import { Link } from "react-router-dom";
import React, { useState } from "react";
import OptionsButton from "../options-button/OptionsButton";

const LoginOptions = ({ width, height, onCistomerClick }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [loginOption, setLoginOption] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  return (
    <div style={{ position: "fixed", top: 20, right: 20 }}>
      <div
        style={{
          height: open ? height - 40 : hover ? 200 : 40,
          width: open ? width - 40 : hover ? 300 : 120,
          background: open ? "#00000066" : hover ? "#FFFFFF" : "#131f48",
          borderStyle: open ? null : "solid",
          borderColor: "#131f48",
          borderRadius: 10,
          overflow: "hidden",
          transition: "all 0.5s",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "1px 1px 0px 0px #00000066",
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {open ? (
          <div
            style={{
              backgroundColor: "#FFFFFF",
              height: width > 599 ? "50%" : "70%",
              width: width > 599 ? "50%" : "70%",
              borderRadius: 10,
              display: "flex",
              flexDirection: "row",
              position: "relative",
            }}
          >
            <div
              onClick={() => {
                setOpen(false);
              }}
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "#131f48",
                position: "absolute",
                left: 20,
                top: 20,
              }}
            >
              CANCEL
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: 10,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: "#00ABD6",
                  fontSize: "26px",
                  fontWeight: "bold",
                  marginTop: "20px",
                  //   width: "80%",
                  // marginLeft: "40px",
                }}
              >
                {loginOption}
              </div>
              <form
                // className="contact-form"
                // onSubmit={sendEmail}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                }}
              >
                <label
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#131f48",
                  }}
                >
                  Username:
                </label>
                <input
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    height: "30px",
                    borderWidth: "2px",
                    borderRadius: 8,
                    color: "#131f48",
                    //   borderStyle: "solid",
                  }}
                  type="text"
                  name="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#131f48",
                  }}
                >
                  Password:
                </label>
                <input
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    height: "30px",
                    borderWidth: "2px",
                    borderRadius: 8,
                    color: "#131f48",
                    //   borderStyle: "solid",
                  }}
                  type="text"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </form>
              {/* <Link to="/">Admin Login</Link> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {(process.env.REACT_APP_OPERATIONS_USER_ID === username &&
                  process.env.REACT_APP_OPERATIONS_USER_PASSWORD ===
                    password) ||
                (process.env.REACT_APP_OPERATIONS_USER_ID_SALES === username &&
                  process.env.REACT_APP_OPERATIONS_USER_PASSWORD_SALES ===
                    password) ? null : (
                  <div
                    style={{
                      height: 60,
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#131f4866",
                      borderRadius: 10,
                      color: "#FFFFFF66",
                      fontWeight: "bold",
                      fontSize: 24,
                      textDecoration: "none",
                      transition: "all 0.5s",
                    }}
                  >
                    LOGIN
                  </div>
                )}

                {(process.env.REACT_APP_OPERATIONS_USER_ID === username &&
                  process.env.REACT_APP_OPERATIONS_USER_PASSWORD ===
                    password) ||
                (process.env.REACT_APP_OPERATIONS_USER_ID_SALES === username &&
                  process.env.REACT_APP_OPERATIONS_USER_PASSWORD_SALES ===
                    password) ? (
                  <Link
                    to="/Rates"
                    style={{
                      height: 60,
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#131f48",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      fontSize: 24,
                      textDecoration: "none",
                      transition: "all 0.5s",
                    }}
                  >
                    LOGIN
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {hover ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <OptionsButton
                  onClick={() => {
                    setOpen(true);
                    setLoginOption("OPERATIONS LOGIN");
                  }}
                  title="OPERATIONS"
                />
                <a href="http://gtdexpress.podzone.net/WintrackDX_GTD/Account/Login">
                  <OptionsButton title="CUSTOMER" />
                </a>

                {/* <OptionsButton
                  onClick={() => {
                    setOpen(true);
                    setLoginOption("SUPPLIER LOGIN");
                  }}
                  title="SUPPLIER"
                /> */}
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  color: "#FFFFFF",
                }}
              >
                LOGIN
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginOptions;
