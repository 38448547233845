import React from "react";

const AnimationBanner = ({ animationTrigger, imageSet, title, value }) => {
  return (
    <>
      <div
        style={{
          height: "100px",
          width: "100%",
          backgroundColor: "#131F48",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: animationTrigger ? 40 : -150,
            transition: "left 1.5s",
            fontWeight: "bold",
            fontSize: "24px",
            color: "#FFFFFF",
          }}
        >
          {title}
        </div>
        <img
          src={imageSet}
          style={{
            height: "60px",
            marginLeft: "20px",
            position: "absolute",
            right: animationTrigger ? 40 : "100%",
            transition: "right 1.5s",
          }}
          alt="loading"
        />
        {value === 3 ? (
          <img
            src={require("../../assets/palletEdge.png")}
            style={{
              height: "60px",
              marginLeft: "20px",
              position: "absolute",
              right: 0,
            }}
            alt="loading"
          />
        ) : null}
      </div>
    </>
  );
};

export default AnimationBanner;
