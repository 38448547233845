import React from "react";
import { Colors } from "../../../../colors/Colors";

const HeaderRow = ({ colorBack, line1, line2, line3 }) => {
  return (
    <div
      style={{
        width: "90%",

        height: 40,
        backgroundColor: colorBack,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: Colors.white,
        flexDirection: "row",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        overflow: "hidden",
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          flex: 0.4,
          borderStyle: "solid",
          height: "100%",
          borderWidth: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {line1}
      </div>
      <div
        style={{
          flex: 0.3,
          borderStyle: "solid",
          borderWidth: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {line2}
      </div>
      <div
        style={{
          flex: 0.3,
          borderStyle: "solid",
          borderWidth: 0.5,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {line3}
      </div>
    </div>
  );
};
export default HeaderRow;
