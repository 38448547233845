import React, { useState } from "react";
import { Colors } from "../../colors/Colors";

import Checkbox from "./check-box/CheckBox";

const RatesAdjuster = ({
  title,
  change,
  onClickCancel,
  setRateAmount,
  setPercentValue,
  RateChangerHandler,
  rateAmount,
  setPdfDisplay,
  onChange,
  surcharge,
}) => {
  const [hover, setHover] = useState("");
  const [textValue, setTextValue] = useState("");
  const [decide, setDecide] = useState("");

  return (
    <div
      style={{
        width: "90%",
        height: "80%",
        backgroundColor: Colors.white,
        borderRadius: 10,
        boxShadow: "0px 2px 2px 2px #00000033",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 40,
          backgroundColor: Colors.primary,
          color: Colors.white,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <h3 style={{ marginLeft: 20 }}>Rates Adjuster</h3>
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            flex: 0.5,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {change === "" ? (
            <>
              <div>Please select rate to change</div>
              <div style={{ height: 10 }} />
              <form
                // className="contact-form"
                //   onSubmit={sendEmail}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#131f48",
                  }}
                >
                  fuel surcharge adjuster
                </label>

                <input
                  value={surcharge}
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    height: "30px",
                    borderWidth: "2px",
                    borderRadius: 8,
                    color: "#131f48",
                    //   borderStyle: "solid",
                  }}
                  onChange={onChange}
                  type="text"
                />
              </form>
              <div
                style={{
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  fontWeight: "bold",
                  padding: 10,
                  borderRadius: 8,
                  marginTop: 20,
                }}
                onClick={() => setPdfDisplay(true)}
              >
                PREVIEW
              </div>
            </>
          ) : !rateAmount ? (
            <form
              // className="contact-form"
              //   onSubmit={sendEmail}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#131f48",
                }}
              >
                Change rate {title}
              </label>
              <input
                style={{
                  marginBottom: 10,
                  width: "100%",
                  height: "30px",
                  borderWidth: "2px",
                  borderRadius: 8,
                  color: "#131f48",
                  //   borderStyle: "solid",
                }}
                onChange={(e) => setTextValue(e.target.value)}
                type="text"
                name="company_name"
              />
              <div
                onMouseOver={() => setHover("submit")}
                onMouseOut={() => setHover("")}
                onClick={() => {
                  setRateAmount(textValue);
                  //   setRatesDisplayAdjsuter("");
                }}
                style={{
                  fontWeight: "bold",
                  fontSize: hover === "submit" ? 20 : 18,
                  marginTop: 10,
                  color: Colors.primary,
                }}
              >
                SUBMIT
              </div>
              <div
                onMouseOver={() => setHover("cancel")}
                onMouseOut={() => setHover("")}
                style={{
                  fontWeight: "bold",
                  fontSize: hover === "cancel" ? 20 : 18,
                  marginTop: 10,
                  color: Colors.secondary,
                }}
                onClick={onClickCancel}
              >
                CANCEL
              </div>
            </form>
          ) : (
            <div
              style={{
                backgroundColor: Colors.primary,
                color: Colors.white,
                fontWeight: "bold",
                padding: 10,
                borderRadius: 8,
                marginTop: 20,
              }}
              onClick={() => RateChangerHandler()}
            >
              confirm
            </div>
          )}
        </div>
        <div style={{ flex: 0.5, height: "100%" }}>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            percent adjuster
          </div>
          <div
            style={{
              width: "100%",
              flex: 1,
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Checkbox
              title={"30%"}
              onClick={() => {
                setPercentValue(30);
                setDecide(30);
              }}
              change={decide === 30 ? true : false}
            />
            <Checkbox
              title={"40%"}
              onClick={() => {
                setPercentValue(40);
                setDecide(40);
              }}
              change={decide === 40 ? true : false}
            />
            <Checkbox
              title={"45%"}
              onClick={() => {
                setPercentValue(45);
                setDecide(45);
              }}
              change={decide === 45 ? true : false}
            />
            <Checkbox
              title={"50%"}
              onClick={() => {
                setPercentValue(50);
                setDecide(50);
              }}
              change={decide === 50 ? true : false}
            />
            <Checkbox
              title={"55%"}
              onClick={() => {
                setPercentValue(55);
                setDecide(55);
              }}
              change={decide === 55 ? true : false}
            />
            <Checkbox
              title={"60%"}
              onClick={() => {
                setPercentValue(60);
                setDecide(60);
              }}
              change={decide === 60 ? true : false}
            />
            <Checkbox
              title={"62.5%"}
              onClick={() => {
                setPercentValue(62.5);
                setDecide(62.5);
              }}
              change={decide === 62.5 ? true : false}
            />
            <Checkbox
              title={"65%"}
              onClick={() => {
                setPercentValue(65);
                setDecide(65);
              }}
              change={decide === 65 ? true : false}
            />
            <Checkbox
              title={"67.5%"}
              onClick={() => {
                setPercentValue(67.5);
                setDecide(67.5);
              }}
              change={decide === 67.5 ? true : false}
            />
            <Checkbox
              title={"70%"}
              onClick={() => {
                setPercentValue(70);
                setDecide(70);
              }}
              change={decide === 70 ? true : false}
            />
            <Checkbox
              title={"72.5%"}
              onClick={() => {
                setPercentValue(72.5);
                setDecide(72.5);
              }}
              change={decide === 72.5 ? true : false}
            />
            <Checkbox
              title={"75%"}
              onClick={() => {
                setPercentValue(75);
                setDecide(75);
              }}
              change={decide === 75 ? true : false}
            />
            <Checkbox
              title={"77.5%"}
              onClick={() => {
                setPercentValue(77.5);
                setDecide(77.5);
              }}
              change={decide === 77.5 ? true : false}
            />
            <Checkbox
              title={"80%"}
              onClick={() => {
                setPercentValue(80);
                setDecide(80);
              }}
              change={decide === 80 ? true : false}
            />
            <Checkbox
              title={"82.5%"}
              onClick={() => {
                setPercentValue(82.5);
                setDecide(82.5);
              }}
              change={decide === 82.5 ? true : false}
            />
            <Checkbox
              title={"85%"}
              onClick={() => {
                setPercentValue(85);
                setDecide(85);
              }}
              change={decide === 85 ? true : false}
            />
            <Checkbox
              title={"87.5%"}
              onClick={() => {
                setPercentValue(87.5);
                setDecide(87.5);
              }}
              change={decide === 87.5 ? true : false}
            />
            <Checkbox
              title={"90%"}
              onClick={() => {
                setPercentValue(90);
                setDecide(90);
              }}
              change={decide === 90 ? true : false}
            />
            <Checkbox
              title={"92.5%"}
              onClick={() => {
                setPercentValue(92.5);
                setDecide(92.5);
              }}
              change={decide === 92.5 ? true : false}
            />
            <Checkbox
              title={"95%"}
              onClick={() => {
                setPercentValue(95);
                setDecide(95);
              }}
              change={decide === 95 ? true : false}
            />
            <Checkbox
              title={"97.5%"}
              onClick={() => {
                setPercentValue(97.5);
                setDecide(97.5);
              }}
              change={decide === 97.5 ? true : false}
            />
            <Checkbox
              title={"100%"}
              onClick={() => {
                setPercentValue(100);
                setDecide(100);
              }}
              change={decide === 100 ? true : false}
            />
            <Checkbox
              title={"102.5%"}
              onClick={() => {
                setPercentValue(102.5);
                setDecide(102.5);
              }}
              change={decide === 102.5 ? true : false}
            />
            <Checkbox
              title={"105%"}
              onClick={() => {
                setPercentValue(105);
                setDecide(105);
              }}
              change={decide === 105 ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RatesAdjuster;
