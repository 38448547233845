import React, { useState } from "react";

const Rows = ({
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  onClickB2,
  onClickB3,
  onClickB4,
  onClickB5,
  onClickB6,
  change2,
  change3,
  change4,
  change5,
  change6,
  type,
}) => {
  const [hover, setHover] = useState("");
  return (
    <div
      style={{
        flex: 0.16,
        borderWidth: 0.2,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          flex: 0.2,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#131f48",
          color: "#ffffff",
          fontSize: type ? 8 : 14,
        }}
      >
        {block1}
      </div>
      <div
        onMouseOver={() => setHover("block2")}
        onMouseOut={() => setHover("")}
        onClick={onClickB2}
        style={{
          flex: 0.16,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: change2
            ? "#00ABD666"
            : hover === "block2"
            ? "#00ABD666"
            : null,
          fontSize: type ? 8 : 14,
        }}
      >
        {block2}
      </div>
      <div
        onMouseOver={() => setHover("block3")}
        onMouseOut={() => setHover("")}
        onClick={onClickB3}
        style={{
          flex: 0.16,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: change3
            ? "#00ABD666"
            : hover === "block3"
            ? "#00ABD666"
            : null,
          fontSize: type ? 8 : 14,
        }}
      >
        {block3}
      </div>
      <div
        onMouseOver={() => setHover("block4")}
        onMouseOut={() => setHover("")}
        onClick={onClickB4}
        style={{
          flex: 0.16,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: change4
            ? "#00ABD666"
            : hover === "block4"
            ? "#00ABD666"
            : null,
          fontSize: type ? 8 : 14,
        }}
      >
        {block4}
      </div>
      <div
        onMouseOver={() => setHover("block5")}
        onMouseOut={() => setHover("")}
        onClick={onClickB5}
        style={{
          flex: 0.16,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: change5
            ? "#00ABD666"
            : hover === "block5"
            ? "#00ABD666"
            : null,
          fontSize: type ? 8 : 14,
        }}
      >
        {block5}
      </div>
      <div
        onMouseOver={() => setHover("block6")}
        onMouseOut={() => setHover("")}
        onClick={onClickB6}
        style={{
          flex: 0.16,
          borderStyle: "solid",
          borderWidth: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: change6
            ? "#00ABD666"
            : hover === "block6"
            ? "#00ABD666"
            : null,
          fontSize: type ? 8 : 14,
        }}
      >
        {block6}
      </div>
    </div>
  );
};
export default Rows;
